<template>
  <div>
    <div>
      <h1 class="text-xl text-grey-light font-medium font-headers mb-3">
        <span> Team Results / </span>
        <span class="font-bold text-grey-dark-3 pt-1">Assessment Overview</span>
      </h1>
    </div>
    <div
      class="flex w-full mb-3 justify-between rounded bg-white shadow py-0 mt-2 p-3"
    >
      <div>
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Team Results and Targets for Competencies
        </p>
        <p class="text-md text-grey-dark-1 ml-3">
          No assessments have been closed yet.
        </p>
      </div>
    </div>
    <div
      class="flex w-full mb-3 justify-between rounded bg-white shadow py-0 mt-2 p-3"
    >
      <div>
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Team Results and Targets for Sub-Competencies
        </p>
        <p class="text-md text-grey-dark-1 ml-3">
          No assessments have been closed yet.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamResultsEmpty",
};
</script>

<style>
</style>